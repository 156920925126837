<template>
  <v-toolbar flat class="mb-4">
    <v-toolbar-title>
      {{ title }}
      <span></span>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <div>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            class="mx-2"
            fab
            small
            color="accent"
            router
            to="/actions"
            v-on="on"
          >
            <v-icon dark>mdi-access-point</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("actions.toolbar") }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            class="mx-2"
            fab
            small
            color="primary"
            router
            to="/actions/create"
            v-on="on"
            v-if="permitted('Actions.Create')"
          >
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("common.create") }}</span>
      </v-tooltip>
    </div>
  </v-toolbar>
</template>

<script>
import { Roles } from "@/_helpers/Role";
export default {
  name: "ActionToolbar",
  props: ["title"],
  computed: {
    Roles() {
      return Roles;
    },
  },
};
</script>
